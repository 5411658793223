import React from "react";
import "./Admission.css";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";




function AdmissionProcedure() {
  return (
    <div className="admission-page">
      <Header />
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">ADMISSION</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5">ADMISSION PROCEDURE</h2>


            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>

              <ul>
                <li>The admission process for B.A/ B.Sc./M.A. classes starts in the first week of July every year.</li>
                <li>The admission forms are available in the college office between 09:30 a.m. to04:00 p.m.</li>
                <li>Online Admission also available at our college website for UG and PG courses.</li>

                <li>The college provides facilities for counselling to the students to help them with the right choice of the subjects.</li>

                <li>Admissions will be strictly on merit basis.</li>
                <li>Seats will be allotted on the merit and as per the direction of the Govt. and University as well as first come first served basis.</li>
                <li>Students seeking admission to this college must apply in the prescribed form at the beginning of each academic year.</li>
                <li>Subject to the availability of seats, normally a student of this college, who has qualified himself/herself for admission to the next higher class,will be admitted to such class provided an application in the prescribed form, duly filled in, is submitted with necessary fees within notified period.</li>
                <li>A student once admitted will be considered as duly enrolled for the academic year and he/she shall be liable to pay the full fees for the whole year unless he/she informs the principal in writing of his/her intention to leave the college at least a week before the commencement of the Examination form.</li>
                <li>Those who fail to pay fees within the stipulated time will forfeit their admission.</li>
                <li>Students from other colleges will be admitted after obtaining requisite permission from the competent authority and verification of their original documents and only if seats are available after granting seats to our own students.</li>
                <li>Students seeking admission shall be careful about selecting their subjects. Subjects selected once will be final and will not be changes under any circumstances.</li>
                <li>After seeking admission to B.A./B.Sc./P.G. classes, students are required to fill up UIN (enrolment) form of the university, before the last date declared by the university and notified by the college. If they fail, their year will not be granted and the college will be not be responsible for the same.</li>
                <li>For admission to the first-year classes it is necessary to produce the original
                  <ul>
                    <li>Secondary and Higher Secondary Passing certificate and statement of marks.</li>
                    <li>School/ College Leaving Certificate.</li>
                    <li>Eligibility Certificate in the case of students passing an Examination other than UP Board that of the S.S.C/H.S.C.</li>
                    <li>Transfer certificate in case of the students coming from other school/college.</li>
                    <li>Migration certificate in case of student coming from outside the jurisdiction of Ayodhya University.</li>
                    <li>Photo Identity proof.</li>
                    <li>Two recent size colour passport photo.</li>
                    <li>Kindly bring a self attested copy of all mentioned documents.</li>
                  </ul>
                </li>


              </ul>
            </p>

          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src="./img/banner-acm.png"
              className="img-fluid h-25 w-100"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AdmissionProcedure;
