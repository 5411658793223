import React from "react";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";

function ManagerMsg() {
  return (
    <div className="about-page">
      <Header />
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">Manager's Message</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5">Manager's Message</h2>
            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              Welcome to the Acharya Chanakya Mahavidyalaya,
              <br />
              Thank you for visiting our website.
              <br />
              Well, the college education plays a vital role in an individual’s
              career and intellectual development. It works as a solid
              foundation for successful education and subsequent life stages.
              These days, we are witnessing many institutions and College
              imparting education at various levels. They are doing their level
              best; however, I believe that education is not just about books
              and exams, It is all about a force and great differentiator.
              <br />
              We created Acharya Chankya P.G. College with the vision to provide
              an excellent opportunity to the students and parents looking for
              holistic development. We built not just the buildings and
              classrooms; but we have created an ecosystem and environment for
              learning and education. We created a culture of excellence and
              performance. With Continuous improvement in our systems,
              facilities and amenities, we have always added one more reason to
              choose Acharya Chankya Mahavidyalya as a preferred destination for
              Graduate, Post Graduate and some vocational course like B.Ed &
              D.El.Ed education.
              <br />
              I must mention that we are proud of a team of talented and
              dedicated teachers who are experts in their respective subjects.
              They have the proven track record to produce excellent results in
              the University examinations. They are the real assets of the
              College. I take pride that our college has produced top scorers
              and achievers.
              <br />
              From a humble beginning, we have now grown into a full-fledged
              education service provider. I wish to thank all who have
              contributed and also who are contributing to the journey of
              College family. I wish to thank students, parents, teachers and
              administrators as well as staff. Achieving excellence is different
              from sustaining the one. I am sure that our people would surely
              maintain the culture of excellence and flourish over a period of
              time.
              <br />
              I wish all the best to all of you and thank you to be a part of
              the College Family.
              <br />
              Happy learning and happy career building.
            </p>
          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src="./img/banner-acm.png"
              className="img-fluid h-25 w-50"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ManagerMsg;
