import React, { useState, useEffect } from "react";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import "./Student.css";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Col, Row, Table, CardBody, Button, Card } from "react-bootstrap";
import setting from "../../setting.json";

const schema = yup.object().shape({
    course_id: yup.string().required("Fill value"),
    part_id: yup.string().required("Fill value"),
    acd_year: yup.string().required("Fill value"),

});

function TimeTable() {

    const { register, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
    });

    const [academicList, setAcademicList] = useState();
    const [coursecList, setCoursecList] = useState();
    const [samesterList, setSamesterList] = useState([]);
    // const [studentList, setStudentList] = useState();
    const [list, setList] = useState([]);
    const [data, setData] = useState([]);
    const [newsCount, setNewsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    const getAcademicYear = async () => {
        await fetch(setting.api + "api/getAcademicYear", {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(u => {
                setAcademicList(u);

            });
    }

    const getAllCourse = async () => {
        await fetch(setting.api + "api/getAllCourse", {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(u => {
                setCoursecList(u.data);

            });
    }

    const getYearSem = async (e) => {
        var id = e.target.value;
        await fetch(setting.api + "api/getYearSem?brc_id=" + id, {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(u => {
                setSamesterList(u.data);

            });
    }

    const getTimeTableCalendarRecord = async (data) => {
        let sr = {
            "course_id": data.course_id,
            "part_id": data.part_id,
            "acd_year": data.acd_year,
            "type": data.type,
        }
        await fetch(setting.api + "api/getTimeTableCalendarRecord", {
            body: JSON.stringify(sr),
            method: "post",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(u => {
                setList(u.data);

            });
    }


    useEffect(() => {

        getAcademicYear();
        getAllCourse();
        setCurrentPage(1);
        setPageCount(Math.ceil((list.length) / pageSize));
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, []);

    useEffect(() => {

        setCurrentPage(1);
        setPageCount(Math.ceil((list.length) / pageSize));
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [list]);

    useEffect(() => {
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [currentPage]);
    useEffect(() => {
        setNewsCount(list.length);

    }, [list]);
    const clickNext = () => {
        if (currentPage === pageCount) {
            setCurrentPage(pageCount);
        } else {
            setCurrentPage(currentPage + 1);
        }
    }

    useEffect(() => {
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [currentPage]);
    useEffect(() => {
        setNewsCount(list.length);

    }, [list]);

    const clickPrev = () => {
        if (currentPage === 1) {
            setCurrentPage(1);
        } else {
            setCurrentPage(currentPage - 1);
        }

    }

    const clickFirst = () => {
        setCurrentPage(1);
    }
    const clickLast = () => {
        setCurrentPage(pageCount);
    }
    console.log(academicList);
    return (

        <div className="student-page">
            <Header />
            <header className="height-75">
                <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
                    <h1 className="text-center fw-semibold">Time Table /Academic Calendar</h1>
                </div>
            </header>

            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
                        <h2 className="mb-4 mb-lg-5">Details</h2>
                    </div>
                    <div className="shop-cart padding-top padding-bottom">
                        <div className="container">
                            <div className="cart-checkout-box mb-widget">
                                <Card>
                                    <CardBody>
                                        <Form onSubmit={handleSubmit(getTimeTableCalendarRecord)} >
                                            <Row >
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Select aria-label="Default select example"
                                                            {...register("acd_year")}
                                                        >
                                                            <option >Academic Year</option>
                                                            {
                                                                academicList && academicList.map((a) =>
                                                                    <option key={a.id} value={a.id}>{a.start_year + '-' + a.end_year}</option>
                                                                )
                                                            }
                                                        </Form.Select>
                                                        {errors.acd_year && <p>{errors.acd_year.message}</p>}
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Select aria-label="Default select example" {...register("course_id")}
                                                            onChange={(e) => getYearSem(e)}
                                                        >
                                                            <option >Course</option>
                                                            {
                                                                coursecList && coursecList.map((a) =>
                                                                    <option key={a.id} value={a.id}>{a.course}</option>
                                                                )
                                                            }
                                                        </Form.Select>
                                                        {errors.course_id && <p>{errors.course_id.message}</p>}
                                                    </Form.Group>
                                                </Col >
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Select aria-label="Default select example"
                                                            {...register("part_id")}

                                                        >
                                                            <option>Semester</option>
                                                            {
                                                                samesterList && samesterList.map((a) =>
                                                                    <option key={a.base_id} value={a.base_id}>{a.base_name}</option>
                                                                )
                                                            }
                                                        </Form.Select>
                                                        {errors.part_id && <p>{errors.part_id.message}</p>}
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Select aria-label="Default select example"
                                                            {...register("type")}
                                                        >
                                                            <option >Type</option>
                                                            <option value="timetable">TimeTable</option>
                                                            <option value="calendar">Academic Calendar</option>

                                                        </Form.Select>
                                                        {errors.acd_year && <p>{errors.acd_year.message}</p>}
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Button type="submit" variant="secondary">Search</Button>

                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>

                            </div>
                            <div className="cart-top mb-widget" style={{ marginTop: 15 }}>
                                <Table bordered hover variant="light">
                                    <thead>
                                        <tr>
                                            <th>Sr.No.</th>
                                            <th>Academic Year</th>
                                            <th>Course</th>
                                            <th>Year/Semester</th>
                                            <th>Acedmic Calender/Time Table</th>

                                        </tr>
                                    </thead>
                                    <tbody id="result">
                                        {data && data.map((a, i) =>
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{a.start_year}-{a.end_year}</td>
                                                <td>{a.course}</td>
                                                <td>{a.base_name}</td>
                                                <td><a href={a.file} target="_blank">View</a></td>

                                            </tr>
                                        )}
                                    </tbody>
                                </Table>

                                <div className='page' style={{ float: "right" }}>

                                    <Button variant='secondary' className='btn' size="sm" onClick={clickFirst}>{`<<`}</Button>{' '}
                                    <Button variant='secondary' className='btn' size="sm" onClick={clickPrev}>{`<`}</Button>
                                    <span >page {currentPage} of {pageCount}</span>
                                    {currentPage < pageCount &&
                                        < Button variant='secondary' className='btn' size="sm" onClick={clickNext}>
                                            {`>`}
                                        </Button>
                                    }
                                    {' '}
                                    {currentPage < pageCount &&
                                        <Button variant='secondary' className='btn' size="sm" onClick={clickLast}>{`>>`}</Button>
                                    }

                                </div>
                            </div>


                        </div>
                    </div >

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default TimeTable;
