import React from "react";
import "./About.css";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";




function Mission() {
  return (
    <div className="about-page">
      <Header />
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">Mission and Vision</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5">Mission and Vision</h2>
            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              To enable students to develop as intellectually alive, socially responsible citizens ever ready for continuous personal and professional growth.
            </p>
            <h4>Vision</h4>

            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              Pursuit of excellence by imparting teaching and training to the young in accordance to the motto derived from the teachings, so that they will become responsible and responsive citizens and will contribute in making the society and the country a better place to live in.
              <ul>
                <li>The College aims at catering to the academic needs of the students with priority to those belonging to economically and socially weak families and with a stress on women's education.</li>
                <li>The college aims at developing the personality of the individuals so as to groom them into worthy citizens with an in-depth faith in oneness of God and Universal Brotherhood.</li>
                <li>The College aims at providing such teaching and tools to the students, by way of introducing Vocational Courses, as may help them develop their entrepreneurial skills and meet the coming global challenges.</li>

                <li>The College aims at sensitizing the students on socio-economic issues with emphasis on gender and human rights as well as on environmental issues through extension activities and also by introducing the students to various co-curricular activities such as sports and games, cultural activities and youth festival, literary activities, seminar etc. so that they can become liberal thinkers with democratic ideals.</li>

                <li>The college aims at making use of ICT aided teaching so that the students can be given exposure to the latest advancement in technology.</li>

              </ul>
            </p>

            <h4>Mission</h4>

            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>

              <ul>
                <li>To impart quality education with high moral standards.</li>
                <li>Provide equal opportunities to students of all sections of the society.</li>
                <li>To introduce the latest technology in teaching programmes.</li>

                <li>To provide conducive environment for creativity and cultural activities.</li>

                <li>Provide equal opportunities to both genders.</li>
                <li>Help develop the skills of students so as to make them more employable.</li>

              </ul>
            </p>

          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src="./img/banner-acm.png"
              className="img-fluid h-25 w-100"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Mission;
