import React, { useState, useEffect } from "react";
import Header from "../components/ChooseSection/Navbar/Header";
import Footer from "../components/Footer/Footer";
import { Table } from "react-bootstrap";
import setting from "../setting.json";

function Ncte() {

    const [list, setList] = useState([]);

    const getNcteOrder = async (data) => {

        await fetch(setting.api + "api/getNcteOrder")
            .then(response => response.json())
            .then(u => {
                setList(u.data);

            });
    }

    useEffect(() => {
        getNcteOrder();
    }, []);

    return (

        <div className="student-page">
            <Header />
            <header className="height-75">
                <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
                    <h1 className="text-center fw-semibold">NCTE Order</h1>
                </div>
            </header>

            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
                        <h2 className="mb-4 mb-lg-5">Details</h2>
                    </div>
                    <div className="shop-cart padding-top padding-bottom">
                        <div className="container">
                            <div className="cart-top mb-widget" style={{ marginTop: 15 }}>
                                <Table bordered hover variant="light">
                                    <thead>
                                        <tr>
                                            <th>Sr.No.</th>
                                            <th>Title</th>
                                            <th>Download</th>

                                        </tr>
                                    </thead>
                                    <tbody id="result">
                                        {list && list.map((a, i) =>
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{a.title}</td>
                                                <td><a href={a.file} target="_blank">View</a></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div >
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Ncte;
