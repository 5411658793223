import React, { useEffect, useState } from 'react';
import { Card, CardHeader } from 'react-bootstrap';
import setting from "../../setting.json";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import DoubleArrow from '@mui/icons-material/ArrowForward';

function ChooseSection() {

  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [data, setData] = useState([]);
  const [newsCount, setNewsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);

  const getAllNews = async () => {
    await fetch(setting.api + "api/getAllNews", {
      method: "GET",
      mode: "cors",
    })
      .then(response => response.json())
      .then(u => {
        setList(u.data);
      });
  }

  const view = () => {
    navigate("/News");
  }

  useEffect(() => {
    getAllNews();
  }, []);

  useEffect(() => {
    setNewsCount(list.length);
    setPageCount(Math.ceil(list.length / pageSize));
    var start = ((currentPage - 1) * pageSize);
    var end = (start + pageSize);
    setData(list.slice(start, end));
    setCurrentPage(1);
  }, [list]);

  useEffect(() => {

    var start = ((currentPage - 1) * pageSize);
    var end = (start + pageSize);
    setData(list.slice(start, end));

  }, [currentPage])

  return (
    <div>
      <div className="container">
        <div className="row g-4">
          <div className="col-lg-7">
            <Card className="d-flex align-items-center border-0 h-100">
              <Card.Body>
                <Card.Title className="text-center text-capitalize mb-3">
                  <h2
                    className="text-center mb-5 px-2 mx-0 "
                    style={{
                      borderBottom: "solid black 2px ",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Pandit Ram Kedar Ram Kishore Tripathi Mahavidyalaya{" "}
                  </h2>
                </Card.Title>
                <Card.Text className="text-center">
                  <p className="px-2 mx-0" style={{ textAlign: "justify" }}>
                    Pt. Ram Kedar Ram Kishore Tripathi P.G.College is managed by Pt Ram Kedar Ram Kishore TripathiSikhshanSansthanSocitey,Sultanpur.This Society was founded under the president-ship of Dr.OmPrakashTripathi . The college is situated 20 km far from SultanpurCity 5 km before ofAligang Market There was no any College in the very rural area. Students of this area,especially girls,faced difficulties in getting admission to colleges.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="col-lg-5">
            <Card
              // className="d-flex align-items-center  h-100"
              style={{
                //   backgroundColor: "grey",
                //   color: "white",
                border: "solid 2px #2f2f30",
                //   fontFamily: "Caveat Brush, cursive",
              }}
            >
              {/* <div className="mt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="white"
                  class="bi bi-clipboard-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5zm-2 0h1v1A2.5 2.5 0 0 0 6.5 5h3A2.5 2.5 0 0 0 12 2.5v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2"
                  />
                </svg>
              </div> */}

              <Card.Body>

                <CardHeader style={{ backgroundColor: "lightgray", color: "black", textAlign: "center" }}><b>News & Notification</b></CardHeader>
                {data && data.map((n) =>

                  <p className='text-start' style={{ borderBottom: "solid 1px", marginTop: "7px" }}>
                    <DoubleArrow />
                    {n.description}
                    <a href={n.description} target="_blank"><Button variant="danger" size="sm" style={{ padding: "unset", marginLeft: "4px" }}>new</Button></a>

                  </p>

                )}
                <Button variant="outline-primary" onClick={view} style={{ float: "right" }}>View All News</Button>
              </Card.Body>

            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseSection;