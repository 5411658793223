import React from "react";
import "./Admission.css";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";


function RulesofLibrary() {
  return (
    <div className="admission-page">
      <Header />
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">RULES AND REGULATIONS</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5">RULES AND REGULATIONS FOR LIBRARY</h2>

            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              <ul>
                <li>
                  Silence must be observed in the Library.
                </li>
                <li>
                  Personal properties are not allowed inside the Library.
                </li>
                <li>
                  Every staff / student of the college is eligible for membership of the Library,and issue their Library Card.
                </li>

                <li>
                  The Library can be utilized by the students and staff from 10 A.M. to 4 P.M. on working days.
                </li>

                <li>
                  All students should sign the entry register of the Library, before entering.
                </li>
                <li>
                  Books borrowing limit: Faculty – 5, Non-Teaching staff – 2, Student – 3.
                </li>
                <li>
                  Principal is empowered to increase additional issue of books to whomever he feels necessary.
                </li>
                <li>
                  Library Card are not transferable.
                </li>
                <li>
                  Books are to be handled very carefully. If a book is lost by the student, he/she shall replace the book (same title, author and edition) or shall pay double the cost of the book as fine.
                </li>
                <li>
                  Students/Faculty before leaving the issue counter must satisfy themselves as to whether the books which they intend to borrow are in good condition and any damage should be immediately. Reported to the Librarian or library staff, failing which the Students/Faculty to whom the book was issued will be held responsible.
                </li>
                <li>
                  Students/Faculty are not permitted to underline, write in, folding / tearing of pages or defaced books in any way whatsoever.
                </li>
                <li>The borrowed book should be returned on or before due date, If not, overdue charge of Rs.5. per day for students will be collected.</li>
                <li>
                  If the due date falls on holidays, return can be done on the following working day without fine.
                </li>

                <li>
                  Misbehaviour in the library will lead to cancellation of membership and serious disciplinary action.
                </li>
                <li>A Students/Faculty who has lost Library Card shall make a written report to the librarian, then original or duplicate Library card will be issued on payment of Rs. 20/-</li>
                <li>
                  The Students/Faculty will be responsible for any loss or non return of books issued against their original or duplicate Library Card.
                </li>
                <li>
                  All students should return their library card and library book before commencement of examination and obtain “NO DUE CERTIFICATE” from the library for getting Admit Card.
                </li>
                <li>
                  Similarly, the staff members who intend to leave the college should settle all the dues and obtain “NO DUE CERTIFICATE” from the library.
                </li>

              </ul>
            </p>
          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src="./img/banner-acm.png"
              className="img-fluid h-25 w-100"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default RulesofLibrary;
