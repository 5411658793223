import React from "react";
import "./Course.css";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";



function BEd() {
  return (
    <div className="course-page">
      <Header />
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">Courses</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5">BACHELOR OF EDUCATION</h2>

            <h4>Course Overview</h4>

            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              To systematise experiences and strengthen the professional competency of in-service teachers.<br />
              To imbibe the knowledge and develop understanding of various methods and approaches of organising learning experiences of secondary school students.<br />
              To develop skills required in selection and organising learning experiences.<br />
              To understand the nature of the learner and of learning processes.<br />
              To develop skills involved in dealing with the academic and personal problems of learners.<br />

            </p>

            <h4>Eligibility for B.Ed Course</h4>

            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              Candidates must have passed Graduate degree with minimum 50 percent marks from a recognized university (Final year candidates are not allowed to appear in the JEE Entrance exam). Eligibility Exception There shall be a relaxation of marks in favor of SC and ST candidates as per norms of the State Govt. and the NCTE rule. Age limit There is no upper age limit for admission to B. Ed. Courses.

            </p>
            <h4>Admission Procedure</h4>
            <p>For admission into B.Ed (Bachelor of Education) Course, an entrance exam conducted by recognized university of Uttar Pradesh appointed for the purpose by the State Govt., in conjunction with the rules of NCTE. This entrance test is called JEE B.Ed entrance</p>

            <h4>Course Fees B.Ed (2 Years) :</h4>
            <p>First Year --- 51250/-<br />
              Second Year - 30000/-</p>

            <h4>Total Seat :</h4>
            <p>Total No of Seat -100 Seats ( Arts 70, Science 30)</p>
          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src="./img/banner-acm.png"
              className="img-fluid h-25 w-100"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BEd;
