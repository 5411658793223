import React from "react";
import "./About.css";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";




function Objective() {
  return (
    <div className="about-page">
      <Header />
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">Objective</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5">Objective</h2>
            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              Acharya ChankyaMahaviadyalya ,SemariSultanpur was established by Dr.OmPrakash Tripathi the manager of the Acharya ChankyaMahavidyalya Samiti in the year of 2007 . The college got affiliated to Dr.Ram Manohar Lohia Avadh University , Faizabad in the Graduate and Post Graduate Courses. Also the college has got affiliation in two yearsB.Ed course. Some vocational courses also run like D.El.ED (BTC) from Parkisha Niyamak Allhabad Uttar Pradesh.
              <br />
              The main aim and objective behind sponsoring this college is to impart value - based education to the young boys and girls of rural area. The college attaches great importance to moral teaching. The college does not merely offer teaching in such subject as would enable young students to earn their bread and butter, but it also Emphasizes grooming them into worthy (morally sound) citizens.
              <br />
              With the area of ten acres, we offer a serene, safe and secure learning environment to the students. A team of enthusiastic and experienced teachers creates an impact on young learners that go beyond the classroom. We aim to reach the highest standards of educational delivery. Our college endeavors to go beyond just the intellectual growth. We believe in shaping up the career of the students and nurture ethical awareness and other good qualities to our students.
              <br />
              The college campus houses a newly constructed spacious building, a multipurpose playground in the lush green surroundings, well-stocked library with remarkable collection, and labs for science stream and computer centre. The college campus also houses a separate section of the building with decorated rooms. Experience the difference of care, knowledge and infrastructure.
            </p>


            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              We provide the better facilities to the students
              <ul>
                <li>Well Maintained Campus</li>
                <li>Grassed Playing Fields</li>
                <li>Interactive Whiteboards In Every Classroom</li>
                <li>Digital Projectors In Teaching Space</li>
                <li>Computer Labs With High Speed Internet Access</li>
                <li>Music Room With All Types Of Musical Instruments</li>
                <li>Well Equipped Science Laboratories</li>
                <li>Multipurpose Hall</li>
                <li>Badminton ,Basket Ball And Hand Ball Courts</li>
                <li>Separate Common Rooms For Girls And Boys</li>
              </ul>
            </p>

            <h4>Vision</h4>

            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>

              <ul>
                <li>To impart quality education with high moral standards.</li>
                <li>Provide equal opportunities to students of all sections of the society.</li>
                <li>To introduce the latest technology in teaching programmes.</li>

                <li>To provide conducive environment for creativity and cultural activities.</li>

                <li>Provide equal opportunities to both genders.</li>
                <li>Help develop the skills of students so as to make them more employable.</li>

              </ul>
            </p>

          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src="./img/banner-acm.png"
              className="img-fluid h-25 w-100"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Objective;
